<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

 <template>
	<div class="sign-in bg-gray-3">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 150px;">

			<a-col :span="24" :md="14" :lg="14" :xl="{span: 14, offset: 0}" class="col-form">
				
				<a-card :bordered="false" class="card-info px-5 mx-0 pt-20">
					<a-row :gutter="[24,24]">
						<a-col :span="24">
							<a-row class="mt-0 pt-2 mb-20">

								<a-col :span="12" class="text-left pl-10">
									<a-button id="btnBordered" type="text" class="primary" v-if="current == 1 || current > 2" @click="previousScreen"><a-icon type="arrow-left" theme="outlined" /> &nbsp;&nbsp;  Back </a-button>
								</a-col>

								<!-- <a-col :span="12" class="text-right pr-10">
									<a-button id="btnBordered" type="text" class="primary" @click="skipScreen"> Skip <a-icon type="arrow-right" theme="outlined" /> &nbsp;&nbsp; </a-button>
								</a-col> -->
							</a-row>

							<a-row class="mt-0 pt-2">

								<a-col :span="24" class="px-10">
									<a-progress strokeColor="#734EBF" trailColor="success" :percent="Math.floor(((current + 1) / 7) * 100)" />
								</a-col>
							
							</a-row>

								<!-- <a-col :span="12" class="text-right pr-10">
									<a-button id="btnBordered" type="text" class="primary" @click="skipScreen"> Skip <a-icon type="arrow-right" theme="outlined" /> &nbsp;&nbsp; </a-button>
								</a-col> -->
							
							<!-- <a-steps :current="current"> -->
								
								<!-- <a-step v-for="item in steps" :key="item.title" :title="item.title" /> -->
							<!-- </a-steps> -->
							<div class="steps-content">
								<!-- {{ steps[current].content }} -->
								<template>
									<a-row ty :gutter="[24,24]">
										
										<a-col :span="24" :md="24" :lg="24">

											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 20px;" v-if="current==0">
												<a-col :span="24" :md="22" :lg="22" :xl="{span: 22, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>What type of Institution do you belong to?</strong>
															</label>
														</a-col>
													</a-row>

													<a-alert
														v-if="errorInstituteType.show"
														:message="errorInstituteType.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-row type="flex" :gutter="[24, 24]" justify="space-around" align="top" class="mt-20 mb-0 pb-0">
														<a-col :span="24" :md="14" :lg="14" :xl="{span: 14, offset: 0}" class="">

															<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
																<a-col :span="12" class="my-0 py-0">
																	<a-card hoverable :bordered="true" class="card-info text-center mb-0 pb-0" :style="instituteType.type == 'Co-operate' ? 'background-color: #734EBF; border-radius: 10px' : 'background-color: white; border-radius: 10px'" @click="handleInstituteTypeSelect('Co-operate')">
																		<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
																			<a-col :span="24" class="my-0 py-0">
																				<h6 :style="instituteType.type == 'Co-operate' ? ' color: #fff !important' : 'color: #141414'">Co-operate</h6>
																			</a-col>
																			<a-col :span="24" class="my-0 py-0">
																				<h4 style="font-size: 40px;">💼</h4>
																			</a-col>
																		</a-row>
																	</a-card>
																</a-col>

																<a-col :span="12" class="my-0 py-0">
																	<a-card hoverable :bordered="true" class="card-info text-center mb-0 pb-0" :style="instituteType.type == 'Education Center' ? 'background-color: #734EBF; border-radius: 10px' : 'background-color: white; border-radius: 10px'" @click="handleInstituteTypeSelect('Education Center')">
																		<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
																			<a-col :span="24" class="my-0 py-0">
																				<h6 :style="instituteType.type == 'Education Center' ? ' color: #fff !important' : 'color: #141414'">Education Center</h6>
																			</a-col>
																			<a-col :span="24" class="my-0 py-0">
																				<h4 style="font-size: 40px;">👩🏽‍🏫</h4>
																			</a-col>
																		</a-row>
																	</a-card>
																</a-col>

															</a-row>

															<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
																<a-col :span="12" class="my-0 py-0">
																	<a-card hoverable :bordered="true" class="card-info text-center mb-0 pb-0" :style="instituteType.type == 'Language School' ? 'background-color: #734EBF; border-radius: 10px' : 'background-color: white; border-radius: 10px'" @click="handleInstituteTypeSelect('Language School')">
																		<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
																			<a-col :span="24" class="my-0 py-0">
																				<h6 :style="instituteType.type == 'Language School' ? ' color: #fff !important' : 'color: #141414'">Language School</h6>
																			</a-col>
																			<a-col :span="24" class="my-0 py-0">
																				<h4 style="font-size: 40px;">🇬🇧</h4>
																			</a-col>
																		</a-row>
																	</a-card>
																</a-col>

																<a-col :span="12" class="my-0 py-0">
																	<a-card hoverable :bordered="true" class="card-info text-center mb-0 pb-0" :style="instituteType.type == 'School' ? 'background-color: #734EBF; border-radius: 10px' : 'background-color: white; border-radius: 10px'" @click="handleInstituteTypeSelect('School')">
																		<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
																			<a-col :span="24" class="my-0 py-0">
																				<h6 :style="instituteType.type == 'School' ? ' color: #fff !important' : 'color: #141414'">School</h6>
																			</a-col>
																			<a-col :span="24" class="my-0 py-0">
																				<h4 style="font-size: 40px;">🏫</h4>
																			</a-col>
																		</a-row>
																	</a-card>
																</a-col>
															</a-row>

															<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
																<a-col :span="12" class="my-0 py-0">
																	<a-card hoverable :bordered="true" class="card-info text-center mb-0 pb-0" :style="instituteType.type == 'University' ? 'background-color: #734EBF; border-radius: 10px' : 'background-color: white; border-radius: 10px'" @click="handleInstituteTypeSelect('University')">
																		<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
																			<a-col :span="24" class="my-0 py-0">
																				<h6 :style="instituteType.type == 'University' ? ' color: #fff !important' : 'color: #141414'">University</h6>
																			</a-col>
																			<a-col :span="24" class="my-0 py-0">
																				<h4 style="font-size: 40px;">🎓</h4>
																			</a-col>
																		</a-row>
																	</a-card>
																</a-col>
															</a-row>
														</a-col>
													</a-row>


													<a-row class="mt-20 pt-10 px-20 mx-20 mb-10">
														<a-col :span="24" class="text-center pr-10">
															<a-button type="primary" v-if="instituteType.type != null" class="" @click="submitInstituteType"> Next <a-icon type="arrow-right" theme="outlined" /> &nbsp;&nbsp; </a-button>
														</a-col>
													</a-row>

												</a-col>
											</a-row>


											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 20px;" v-if="current==1">
												<a-col :span="24" :md="11" :lg="11" :xl="{span: 11, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Basic details of the school</strong>
															</label>
														</a-col>
													</a-row>

													<a-alert
														class="mt-20"
														v-if="errorBasicDetails.show"
														:message="errorBasicDetails.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon/>

													<a-form-model
														id="components-form-demo-normal-login"
														:form="basicDetailsForm"
														class="login-form mt-20 pt-20"
														@submit="handleSubmitBasicDetails"
														:hideRequiredMark="true">

														<a-row :gutter="[24, 24]" class="mt-0 mb-0 pb-0">
															<a-col :span="24" class="my-0 py-0 text-center">

																<a-button id="classIconBtn" @click="handleSelectLogo">
																	<img id="previewClassIcon" style="margin: 0px; width: 115px; height: 115px;" v-if="instituteIcon.fileUrl != null" :src="instituteIcon.fileUrl"/>
																	<span v-if="instituteIcon.fileUrl == null">
																		<svg class="dark:text-white mb-5" style="width: 22px; color: #444444" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
																			<path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M4 18V8c0-.6.4-1 1-1h1.5l1.7-1.7c.2-.2.4-.3.7-.3h6.2c.3 0 .5.1.7.3L17.5 7H19c.6 0 1 .4 1 1v10c0 .6-.4 1-1 1H5a1 1 0 0 1-1-1Z"/>
																			<path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
																		</svg><br/>
																		Add Photo
																	</span>
																</a-button>
															</a-col>
														</a-row>

														<a-input 
															hidden
															id="instituteLogo"
															type="file"
															ref="file"
															@change="handleFileChange"/>

														<a-row :gutter="[24,24]">
															<a-col :span="24" :sm="24" :md="24" :lg="24">
																<a-form-item class="py-0 my-0" label="Institute Name" :colon="false">
																	<a-input
																		v-model="basicDetails.name"
																		type="text" 
																		placeholder="Institute Name" />
																</a-form-item>
															</a-col>

															<a-col :span="24" :sm="24" :md="24" :lg="24">
																<a-form-item class="py-0 my-0" label="Email Address" :colon="false">
																	<a-input
																		v-model="basicDetails.email"
																		type="email" 
																		placeholder="Email Address" />
																</a-form-item>
															</a-col>
														</a-row>

														<a-row :gutter="[24,24]">
															<a-col :span="24" :sm="24" :md="24" :lg="24">
																<a-input-group>
																	<a-row :gutter="24">
																		<a-col :span="5" class="w-100 pr-0 mr-0">
																			<a-form-item class="mb-10 w-100" label="Phone Number" :colon="false">
																				<vue-country-code
																					style="height: 40px; width: 100%; margin-top: 4px;"
																					@onSelect="onSelectCountry"
																					:enableSearchField="true"
																					defaultCountry="tz">
																				</vue-country-code>
																			</a-form-item>
																		</a-col>
																		<a-col :span="19" class="ml-0 pl-1">
																			<a-form-item class="mb-10" :colon="false">
																				<a-input 
																					style="margin-top: 32px; width: 100%;"
																					v-model="basicDetails.phone"
																					placeholder="Phone number" type="number" />
																			</a-form-item>
																		</a-col>
																		
																	</a-row>
																	
																</a-input-group>
															</a-col>

															<!-- <a-col :span="24" :sm="24" :md="24" :lg="24">
																<a-form-item class="py-0 my-0" label="Location" :colon="false">
																	<a-input
																		v-model="basicDetails.location"
																		type="text" 
																		placeholder="School Location" />
																</a-form-item>
															</a-col> -->

															<!-- <a-col :span="24" :sm="24" :md="24" :lg="24">
																<a-form-item class="py-0 my-0" label="Date of Birth" :colon="false">
																	<a-date-picker
																	format="DD MMM YYYY"
																	style="width: 100%"
																	v-decorator="[
																		'dateOfBirth', 
																		{ rules: [] }
																	]"
																	placeholder="Birthday" />
																</a-form-item>
															</a-col> -->
														</a-row>

														<a-row :gutter="[24,24]">
															<a-col :span="24" :sm="24" :md="24" :lg="24">
																<!-- <label style="font-size: 16px; margin-bottom: 5px">Location</label><br/> -->
																<a-form-item class="py-0 my-0" label="Location" :colon="false">
																	<vue-google-autocomplete 
																		id="locationInput" 
																		style="width: 100% !important"
																		classname="" 
																		placeholder="" 
																		v-on:placechanged="getAddressData">
																	</vue-google-autocomplete>
																</a-form-item>
															</a-col>
														</a-row>
														
														
														<a-form-item class="mt-2 text-center mt-20 pt-5">
															<a-button :loading="basicDetails.loading" v-if="instituteIcon.fileUrl != null" type="primary" style="width: 100px !important;" block html-type="submit" class="login-form-button mt-10 text-white" >
																NEXT
															</a-button>
														</a-form-item>
														
													</a-form-model>

													
														
												</a-col>
											</a-row>


											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==2">
												<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Setup your school account</strong>
															</label>
														</a-col>
													</a-row>

													<a-alert
														class="mt-20"
														v-if="errorSetup.show"
														:message="errorSetup.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>


													<a-row :gutter="[24,24]">
														<a-col :span="24" class="mt-20">
															<label style="font-size: 16px"><strong><span style="font-size: 18px;">🙋🏾‍♂️</span> Members Registration</strong></label>
															<p class="description" style="font-size: 14px;">Allow members to join classes without filling up their registration ID</p>

															<a-radio-group v-model="setup.uuid">
																<a-radio :value="false">Yes they can</a-radio>
																<a-radio :value="true">No they can’t</a-radio>
															</a-radio-group>
														</a-col>
													</a-row>

													<a-row ty :gutter="24" class="text-center mt-20">
														<a-col :span="24">
															<a-button :loading="setup.loading" v-if="setup.uuid != null" type="primary" style="width: 100px !important;" block @click="submitSetup" class="login-form-button mt-10 text-white" >
																Next
															</a-button>
														</a-col>
													</a-row>

													
												</a-col>
											</a-row>


											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 20px;" v-if="current==3">
												<a-col :span="24" :md="22" :lg="22" :xl="{span: 22, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Which type of subjects do you teach?</strong>
															</label>
														</a-col>
													</a-row>

													<a-alert
														v-if="errorLearning.show"
														:message="errorLearning.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-row type="flex" :gutter="[24, 24]" justify="space-around" align="top" class="mt-20 mb-0 pb-0">
														<a-col :span="24" :md="10" :lg="10" :xl="{span: 10, offset: 0}" class="">

															<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
																<a-col :span="24" class="my-0 py-0">
																	<a-card hoverable :bordered="true" class="card-info text-center mb-0 pb-0" :style="learning.isAcademic ? 'background-color: #734EBF; border-radius: 10px' : 'background-color: white; border-radius: 10px'" @click="handleLearningSelection(true)">
																		<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
																			<a-col :span="8" class="my-0 py-0">
																				<h4 style="font-size: 40px;">🧑🏽‍🎓</h4>
																			</a-col>
																			<a-col :span="16" class="my-0 py-0 text-left">
																				<h6 :style="learning.isAcademic ? ' color: #fff !important' : 'color: #141414'">Academic Subjects</h6>
																				<p class="description pt-0 mt-0">Eg: Mathematics</p>
																			</a-col>
																		</a-row>
																		
																		
																	</a-card>
																</a-col>
															</a-row>

															<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
																<a-col :span="24" class="my-0 py-0">
																	<a-card hoverable :bordered="true" class="card-info text-center mb-0 pb-0" :style="learning.isAcademic == false ? 'background-color: #734EBF; border-radius: 10px' : 'background-color: white; border-radius: 10px'" @click="handleLearningSelection(false)">
																		<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
																			<a-col :span="8" class="my-0 py-0">
																				<h4 style="font-size: 40px;">💼</h4>
																			</a-col>
																			<a-col :span="16" class="my-0 py-0 text-left">
																				<h6 :style="learning.isAcademic == false ? ' color: #fff !important' : 'color: #141414'">Professional Subjects</h6>
																				<p class="description pt-0 mt-0">Eg: Accounting</p>
																			</a-col>
																		</a-row>
																		
																		
																	</a-card>
																</a-col>
															</a-row>
														</a-col>
													</a-row>


													<a-row class="mt-20 pt-10 px-20 mx-20 mb-10">
														<a-col :span="24" class="text-center pr-10">
															<a-button type="primary" :loading="learning.loading" v-if="learning.isAcademic != null" class="" @click="submitLearning"> Next <a-icon type="arrow-right" theme="outlined" /> &nbsp;&nbsp; </a-button>
														</a-col>
													</a-row>

												</a-col>
											</a-row>


											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 20px;" v-if="current==4">
												<a-col :span="24" :md="16" :lg="16" :xl="{span: 16, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Which education system do you fit in?</strong>
															</label>
														</a-col>
													</a-row>

													<a-alert
														class="mt-20"
														v-if="errorEducationSystem.show"
														:message="errorEducationSystem.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon/>

													
														
													<a-row :gutter="[24,24]" v-if="!educationSystem.loading">
														<a-col :span="24" class="mt-20">
															<a-badge  
																v-for="option in educationSystems"
																:key="option.uuid"
																style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
																:count="`${option.name}`" 
																@click="handleEducationSystemSelect(option.uuid)"
																:number-style="educationSystem.uuid == option.uuid ? 
																	{backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '4px', height: '30px'} : 
																	{border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: '#FFFFFF !important', paddingTop: '4px', height: '30px'}"/>
														</a-col>
													</a-row>

													<div v-if="educationSystem.loading">

														<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 10vh">

															<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

																<a-spin class="text-primary" />

															</a-col>

														</a-row>
													</div>

													<a-row ty :gutter="24" class="text-center mt-20">
														<a-col :span="24">
															<a-button :loading="educationSystem.loading" v-if="educationSystem.uuid != null" @click="submitEductionSystem" type="primary" style="width: 100px !important;" block class="row login-form-button mt-10 text-white" >
																Next
															</a-button>
														</a-col>
													</a-row>
														
												</a-col>
											</a-row>


											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==5">
												<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Which Education level are you targeting?</strong>
															</label>
														</a-col>
													</a-row>

													<a-alert
														class="mt-20"
														v-if="errorEducationLevel.show"
														:message="errorEducationLevel.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-form
														class="login-form mt-20"
														:hideRequiredMark="true">

														<a-row :gutter="[24,24]" v-if="!educationLevel.loading">
															<a-col :span="24" :sm="24" :md="24" :lg="24">
																<a-form-item 
																	class="py-0 my-0"
																	label=""
																	placeholder="Select Education Level"
																	:colon="false">
																	<a-select placeholder="Select Education Level" v-model="educationLevel.uuid">
																		<a-select-option v-for="option in educationLevels"
																			:value="option.uuid"
																			:key="option.name">
																			{{ option.name }}
																		</a-select-option>
																	</a-select>
																</a-form-item>
															</a-col>
														</a-row>

														<div v-if="educationLevel.loading">

															<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 10vh">

																<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

																	<a-spin class="text-primary" />

																</a-col>

															</a-row>
														</div>

														<a-form-item class="mt-2 text-center mt-20 pt-20">
															<a-button :loading="educationLevel.loading" v-if="educationLevel.uuid" type="primary" style="width: 100px !important;" block @click="submitEducationLevel" class="login-form-button mt-10 text-white" >
																Next
															</a-button>
														</a-form-item>
														
													</a-form>
												</a-col>
											</a-row>


											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==6">
												<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Select the subjects you’ll teach</strong>
															</label>
														</a-col>
													</a-row>

													<a-alert
														class="mt-20"
														v-if="errorSubject.show"
														:message="errorSubject.message"
														type="error"
														closable
														:after-close="handleClose"
														show-icon 
													/>

													<a-row :gutter="[24,24]" v-if="!subject.loading">
														<a-col :span="24" class="mt-20">
															<a-badge  
																v-for="option in subjects"
																:key="option.uuid"
																style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
																:count="`${option.name}`" 
																@click="handleSubjectSelect(option.uuid)"
																:number-style="subject.uuids != null && subject.uuids.includes(option.uuid) ? 
																	{backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '4px', height: '30px'} : 
																	{border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: '#FFFFFF !important', paddingTop: '4px', height: '30px'}"/>
														</a-col>
													</a-row>

													<div v-if="subject.loading">

														<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 10vh">

															<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

																<a-spin class="text-primary" />

															</a-col>

														</a-row>
													</div>

													<a-row ty :gutter="24" class="text-center mt-20">
														<a-col :span="24">
															<a-button :loading="subject.loading" v-if="subject.uuids != null && subject.uuids.length > 0" type="primary" style="width: 100px !important;" block @click="submitSubjects" class="login-form-button mt-10 text-white" >
																Next
															</a-button>
														</a-col>
													</a-row>
													
												</a-col>
											</a-row>
		
										</a-col>
									</a-row>
								</template>
							</div>
							<div class="steps-action">
								<!-- <a-button v-if="current < steps.length - 1" type="primary" @click="next">Next</a-button>
								
								<a-button v-if="current > 0" style="margin-left: 8px" @click="prev">Previous</a-button> -->
							</div>
						</a-col>
					</a-row>

				</a-card>
			</a-col>


		</a-row>

		<!-- <a-modal v-model="skipConfirmation.showModal" :footer="null">
			
			<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
				<a-col :span="24" class="my-0 py-0">
					<h6 style="font-size: 42px;" class="text-center">✋🏽</h6>
					<p style="font-size: 16px; font-weight: 500;">
						Providing your details helps you discover classes, Tutors & study materials that perfectly 
						match your learning goals. Are you sure you want to skip this step?
					</p>
				</a-col>
			</a-row>

			<a-row :gutters="24" class="mt-20">
				<a-col :span="24" class="text-center">
					<a-button id="textBtn" type="text" class="text-primary txtBtn" @click="toHomePage">
						Skip
					</a-button>
					&nbsp;&nbsp;
					<a-button type="primary" class="text-white txtBtn" style="" @click="cancelModal">
						Stay on the page
					</a-button>
				</a-col>
			</a-row>
			
		</a-modal> -->
		
	</div>
</template>

<script>
	import VueCountryCode from "vue-country-code-select";
	import VueGoogleAutocomplete from "vue-google-autocomplete";
    import { notification } from 'ant-design-vue';

	export default ({
		components: {
			VueCountryCode,
			VueGoogleAutocomplete
		},

		props: ['userLocation'],

		data() {
			return {
	
				current:0,
				steps: [
					{
						title: 'Institute Type',
						content: 'First-content',
					},
					{
						title: 'Basic Details',
						content: 'Second-content',
					},
					{
						title: 'Setup',
						content: 'Third-content',
					},
					{
						title: 'Teaching Preference',
						content: 'First-content',
					},
					{
						title: 'Education System',
						content: 'Second-content',
					},
					{
						title: 'Education Level',
						content: 'Third-content',
					},
					{
						title: 'Subjects',
						content: 'Fourth-content',
					},
				],

				user: {},
				institute: {},
				instituteDetails: {},

				instituteIcon: {
					file: null,
					fileUrl: null,
					fileThumbnail: null,
				},

				subjects: [],
				educationLevels: [],
				educationSystems: [],

				uploadPercentage: 0,

				instituteType: {
					type: null,
					loading: false
				},
				errorInstituteType: {
					message: null,
					show: false,
				},

				basicDetails: {
					name: null,
					email: null,
					phone: null,
					countryPhoneCode: null,
					countryIsoCode: null,
					countryCode: null,
					latLon: null,
					location: null,
					logitude: null,
					latitude: null,
					thumbnail: null,
					creator: null,
					loading: false
				},

				errorBasicDetails: {
					message: null,
					show: false,
				},


				setup: {
					uuid: null,
					loading: false
				},
				errorSetup: {
					message: null,
					show: false,
				},

				skipConfirmation: {
					showModal: false
				},

				learning: {
					isAcademic: null,
					loading: false
				},
				errorLearning: {
					message: null,
					show: false,
				},

				educationSystem: {
					uuid: null,
					loading: false
				},
				errorEducationSystem: {
					message: null,
					show: false,
				},

				educationLevel: {
					uuid: undefined,
					loading: false
				},
				errorEducationLevel: {
					message: null,
					show: false,
				},

				subject: {
					uuids: [],
					loading: false
				},
				errorSubject: {
					message: null,
					show: false,
				},
				
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			// this.personalDetailsForm = this.$form.createForm(this, { name: 'personal_details' });
			this.basicDetailsForm = this.$form.createForm(this, { name: 'basic_details_form' });
		},
		created() {
			this.getInsituteProfile();
			this.getUserDetails();
			this.getUserLocationDetails();
		},
		methods: {

			nextScreen() {
				if(this.current == 6) {
					this.notify("You have successfully finish onboarding", 'success');
					this.$router.push({ path: '/profile' }).then(() => { this.$router.go() })
				}else{
					this.current++;
				}
			},

			previousScreen() {
				if(this.current > 0) {
					this.current--;
				}
			},

			skipScreen() {
				this.skipConfirmation.showModal = true;
			},

			cancelModel() {
				this.skipConfirmation.showModal = false;
			},

			toHomePage() {
				this.$router.push({ path: '/profile' }).then(() => { this.$router.go() })
			},


			handleClose() {
				this.error.show = false;
			},


			async getUserDetails() {
				let userDetails = JSON.parse(await localStorage.getItem('user_details'))

				this.user = userDetails
			},


			async getInsituteProfile() {

                this.loadingData = true;

				let instituteInfo = await localStorage.getItem("institution_details")

                let instituteDetails = instituteInfo != null ? JSON.parse(instituteInfo) : {};

				this.instituteDetails = instituteDetails;

				this.loadingData = true;

                let url = `${this.$BACKEND_URL}/institutions?uuid=${instituteDetails.uuid}&populate=true`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {

						this.instituteType.type = response.data.data.institutionType
						this.basicDetails.name = response.data.data.name;
						this.basicDetails.email = response.data.data.emailAddress;
						this.basicDetails.location = response.data.data.location;
						this.basicDetails.phone = response.data.data.phoneNumber != null ? response.data.data.phoneNumber.substring(1) : response.data.data.phoneNumber;
						this.basicDetails.location = response.data.data.location;
						this.instituteIcon.fileUrl = response.data.data.thumbnail;
						this.basicDetails.thumbnail = response.data.data.thumbnail;
                    //     this.personalDetails.firstName = response.data.data.firstName;
                    //     this.personalDetails.lastName = response.data.data.lastName;
                    //     this.personalDetails.gender = response.data.data.gender;
                    //     this.learning.isAcademic = response.data.data.isAcademic;

					// 	this.getEducationSystems(response.data.data.isAcademic)

					// 	if(response.data.data.educationSystems != null && response.data.data.educationSystems.length > 0) { 
                    //     	this.educationSystem.uuid = response.data.data.educationSystems[0].uuid;
					// 		this.getEducationLevels(response.data.data.educationSystems[0].uuid);
					// 	}

					// 	if(response.data.data.educationGrades != null && response.data.data.educationGrades.length > 0) { 
					// 		let gradesLength = response.data.data.educationGrades.length;
                    //     	this.grade.uuid = response.data.data.educationGrades[gradesLength - 1].uuid;
					// 		this.grade.levelUuid = response.data.data.educationGrades[gradesLength - 1].educationLevel;
					// 		this.getGrades(response.data.data.educationGrades[gradesLength - 1].educationLevel);
					// 		this.getSubjects(response.data.data.educationGrades[gradesLength - 1].educationLevel);
					// 	}
						
                    //     this.subject.uuids = response.data.data.subjects != null && response.data.data.subjects.length > 0 ? response.data.data.subjects.map(sub => sub.uuid) : [];
						
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },



			async getEducationSystems() {

				this.educationSystem.loading = true;

				const currentPage = 1;
				const perPage = 1000;

				// let url = `${this.$BACKEND_URL}/education/systems?countryCode=${this.user.countryCode}&isAcademic=${this.learning.isAcademic}&page=${currentPage}&size=${perPage}`;

				let url = `${this.$BACKEND_URL}/education/systems?isAcademic=${this.learning.isAcademic}&page=${currentPage}&size=${perPage}`;
				
				this.$AXIOS.get(url).then((response) => {

					if(this.learning.isAcademic) {
						this.educationSystems = response.data.data.filter(edu => edu.countryCode == this.userLocation.countryIsoCode || edu.isGlobal);
					}else{
						this.educationSystems = response.data.data;
					}

					this.educationSystem.loading = false;
				
				}).catch(error => {

					this.educationSystem.loading = false;
		
					this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
				
				})
			},


			async getEducationLevels(educationSystemUuid) {

				this.educationLevel.loading = true;

				const currentPage = 1;
				const perPage = 1000;

				let url = `${this.$BACKEND_URL}/education/levels?educationSystem=${educationSystemUuid}&page=${currentPage}&size=${perPage}`;

				this.$AXIOS.get(url).then((response) => {

					this.educationLevels = response.data.data;

					this.educationLevel.loading = false;

				}).catch(error => {

					this.educationLevel.loading = false;
		
					this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
				
				})
			},


			async getSubjects() {

				this.subject.loading = true;

				const currentPage = 1;
				const perPage = 1000;

				let url = `${this.$BACKEND_URL}/education/subjects?educationLevels=${this.educationLevel.uuid}&page=${currentPage}&size=${perPage}`;

				this.$AXIOS.get(url).then((response) => {

					this.subjects = response.data.data;

					this.subject.loading = false;

				}).catch(error => {

					this.subject.loading = false;
		
					this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
				
				})
			},
			

			async getUserLocationDetails() {

				let token = await localStorage.getItem("user_token")

                let url = `${this.$BACKEND_LEGACY_URL}/geo-location`;

                const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

                this.$AXIOS.get(url, config).then(async(response) => {

					this.basicDetails.countryPhoneCode = response.data.countryPhoneCode.substring(1)
					this.basicDetails.countryIsoCode = response.data.countryIsoCode.toLowerCase();
					this.basicDetails.countryCode = response.data.countryIsoCode.toLowerCase();
					this.basicDetails.latLon = [response.data.countryLat, response.data.countryLon];
					
                }).catch(async(error) => {

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			handleSelectLogo() {
				document.getElementById("instituteLogo").click()
			},

			handleFileChange(event) {
				this.instituteIcon.file = event.target.files[0];
				this.instituteIcon.fileThumbnail = event.target.files[0].name;
				this.instituteIcon.fileUrl = URL.createObjectURL(event.target.files[0])

				// this.handleSubmitFile()
			},

			getFileUrl() {
				
			},


			handleInstituteTypeSelect(type) {
				this.instituteType.type = type;
			},

			submitInstituteType() {
				this.nextScreen();
			},

			onSelectCountry({name, iso2, dialCode}) {
				// console.log(name)
				// console.log(iso2)
				this.basicDetails.country = name.toLowerCase();
				this.basicDetails.countryPhoneCode = dialCode;
			},

			handleSubmitBasicDetails(e) {
				e.preventDefault();

				this.basicDetails.name = this.basicDetails.name;
				this.basicDetails.email = this.basicDetails.email;
				this.basicDetails.location = this.basicDetails.location;
				let constPhone = this.basicDetails.phone;
				this.basicDetails.location = this.basicDetails.location;
				

				if(constPhone.substring(0, 4).includes(this.basicDetails.countryPhoneCode) && !constPhone.includes("+")) {
					this.basicDetails.phone = "+" + constPhone;
				}

				else if(constPhone.substring(0, 4).includes(this.basicDetails.countryPhoneCode) && constPhone.includes("+")) {
					this.basicDetails.phone =  constPhone;
				}

				else if(constPhone[0] == "0" || constPhone[0] == 0) {
					this.basicDetails.phone = "+" + this.basicDetails.countryPhoneCode + "" + constPhone.substring(1)
				}

				else if(constPhone[0] != "0" || constPhone[0] != 0) {
					this.basicDetails.phone = "+" + this.basicDetails.countryPhoneCode + "" + constPhone
				}

				else {
					this.basicDetails.phone = constPhone;
				}

				if(this.instituteIcon.file != null) {
					this.handleSubmitFile()
				}else{
					this.submitBasicDetails(null)
				}
			
				
			},


			async handleSubmitFile() {
				// e.preventDefault()
				
				this.basicDetails.loading = true;

				let token = await localStorage.getItem("user_token")

				const config = {
					headers: {
						'Content-Type': 'multipart/form-data',
						'Authorization': `Bearer ${token}`,
					},
				};

				const formData = new FormData(); 

				formData.append(`files`, this.instituteIcon.file, this.instituteIcon.fileThumbnail); 

				let url = `${this.$BACKEND_URL}/uploads`;
				
				this.$AXIOS.post(url, formData, config).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.submitBasicDetails(response.data.data[0])
					}
				}).catch((err) => {
					console.log(err)
					this.basicDetails.loading = false;
					
					this.notify(err.response != null  && err.response.data != null ? err.response.data.message : "Connection error", 'error');
					
				});

				
			},


			getAddressData(addressData, placeResultData, id) {
				console.log(addressData)
				this.basicDetails.latitude = addressData.latitude; 
				this.basicDetails.longitude = addressData.longitude;
				this.basicDetails.location = `${addressData.route}, ${addressData.locality}, ${addressData. country}`
			},


			submitBasicDetails(attachment) {
				this.basicDetails.loading = true;

				let url = `${this.$BACKEND_URL}/institutions/${this.instituteDetails.uuid}`;

				let payload = {
					name: this.basicDetails.name,
					institutionType: this.instituteType.type,
					emailAddress: this.basicDetails.email,
					phoneNumber: this.basicDetails.phone,
					country: this.basicDetails.countryCode,
					location: this.basicDetails.location,
					latLon: [this.basicDetails.latitude, this.basicDetails.longitude],
				}

				if(attachment != null) {
					let { path, mimetype, size } = attachment;
					payload.thumbnail = path;
				}

				this.$AXIOS.patch(url, payload).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						let {token, ...institute_details} = response.data.data

						this.institute = institute_details;

						this.nextScreen();
						
					}
					this.basicDetails.loading = false;
				}).catch((err) => {
					console.log(err)
					this.basicDetails.loading = false;
					
					this.errorBasicDetails.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
					this.errorBasicDetails.show = true
				});
			},


			submitSetup() {
				// this.setup.loading = true;

				// let url = `${this.$BACKEND_URL}/institutions/${this.institute.uuid}`;

				// let payload = {
				// 	verifyMembers: this.setup.uuid,
				// }

				// this.$AXIOS.patch(url, payload).then(async(response) => {
				// 	if (response.status >= 200 && response.status < 210) {

						this.nextScreen();
						
				// 	}
				// 	this.setup.loading = false;
				// }).catch((err) => {
				// 	console.log(err)
				// 	this.setup.loading = false;
					
				// 	this.errorSetup.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
				// 	this.errorSetup.show = true
				// });
			},


			handleLearningSelection(isAcademic) {
				this.learning.isAcademic = isAcademic;
			},

			submitLearning() {
				this.getEducationSystems();
				this.nextScreen();
			},


			handleEducationSystemSelect(uuid) {
				this.educationSystem.uuid = uuid;
			},


			async submitEductionSystem() {
				this.getEducationLevels(this.educationSystem.uuid)

				this.educationSystem.loading = true;

				let url = `${this.$BACKEND_URL}/institutions/${this.institute.uuid}`;

				this.$AXIOS.patch(url, { educationSystems: [this.educationSystem.uuid], verifyMembers: this.setup.uuid }).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.nextScreen();
						
					}
					this.educationSystem.loading = false;
				}).catch((err) => {
					console.log(err)
					this.educationSystem.loading = false;

					this.errorEducationSystem.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
					this.errorEducationSystem.show = true
				
				});
			},


			async submitEducationLevel() {
				this.getSubjects(this.educationLevel.uuid)

				this.educationLevel.loading = true;

				let url = `${this.$BACKEND_URL}/institutions/${this.institute.uuid}`;

				this.$AXIOS.patch(url, { educationLevels: [this.educationLevel.uuid] }).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.nextScreen();
						
					}
					this.educationLevel.loading = false;
				}).catch((err) => {
					console.log(err)
					this.educationLevel.loading = false;

					this.errorEducationLevel.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
					this.errorEducationLevel.show = true
				
				});
			},


			handleSubjectSelect(uuid) {
				if(this.subject.uuids != null && this.subject.uuids.includes(uuid)) {
					this.subject.uuids.splice(this.subject.uuids.indexOf(uuid), 1);
				}else{
					this.subject.uuids.push(uuid)
				}
			},


			submitSubjects() {
				this.subject.loading = true;

				let url = `${this.$BACKEND_URL}/institutions/${this.institute.uuid}`;

				let payload = {
					subjects: this.subject.uuids,
				}

				this.$AXIOS.patch(url, payload).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.nextScreen();
						
					}
					this.subject.loading = false;
				}).catch((err) => {
					console.log(err)
					this.subject.loading = false;
					
					this.errorSubject.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
					this.errorSubject.show = true
				});
			},

			

			notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },

		},
	})

</script>

<style lang="scss" scoped>
	body {
		background-color: #ffffff;
	}
	.ant-steps-item-process .ant-steps-item-icon { background: red; }
	#signinBtn, #signinBtn:hover {
		background-color: #632163 !important;
		color: white !important;
	}
	#submitUsernameBtn, #submitUsernameBtn:hover, #submitTokenBtn, #submitTokenBtn:hover {
		background-color: #632163 !important;
		color: white !important;
	}
	#backBtn, #backBtn:hover {
		color: #632163 !important;
		border-color: #632163 !important;
		background-color: white !important;
	}

	.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: red !important;
}

.custome-step .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: red !important;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
//    background-color: #632163 !important;
   color: #ffffff;
}

.ant-steps-item-process .ant-steps-item-icon {
    border-color: #632163 !important;
    background: #632163 !important;
}

#divId {
	display: inline-block !important;
}
</style>
